import React from 'react';
import { Picker } from '../Picker/Picker';
/**
 * Bronson TimePicker component.
 * @see https://bronson.vwfs.tools/bluelabel/components/detail/bronson-timepicker.html
 * @constructor
 */
export function TimePicker({ className = '', dateFormat = 'H:i', disabled = false, error = false, flatpickrOptions, flatpickrProps, id, locale, maxTime, minTime, name, noBackground = true, onBlur, onChange, onError, placeholder, time24hr = false, timePickerClassName = '', value, testId, ...otherProps }) {
    return (React.createElement(Picker, { className: className, componentClassName: "c-timepicker", dateFormat: dateFormat, disabled: disabled, error: error, flatpickrOptions: {
            ...flatpickrOptions,
            enableTime: true,
            noCalendar: true,
            time_24hr: time24hr,
            maxTime,
            minTime,
        }, flatpickrProps: flatpickrProps, id: id, locale: locale, maskedInput: false, mode: "single", name: name, noBackground: noBackground, onBlur: onBlur, onChange: onChange, onError: onError, pickerClassName: timePickerClassName, placeholder: placeholder, value: value, testId: testId, ...otherProps }));
}
