import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button } from '../Button/Button';
import { Input } from '../Input/Input';
/*
 * Bronson InlineEditable component.
 */
export const InlineEditable = ({ className, editLabel = 'Edit value', onReset, onSubmit, resetLabel = 'Reset value', submitLabel = 'Submit value', submittedValue, valid, value, ...otherProps }) => {
    /**
     * Get ref for input element.
     */
    const inputRef = useRef(null);
    /**
     * Manage active state to show editable mode.
     */
    const [isActive, setIsActive] = useState(false);
    /**
     * Set the {isActive} state to true and memoize the function.
     * @type {(function(): void)|*}
     */
    const activate = useCallback(() => {
        setIsActive(true);
        inputRef?.current?.focus();
    }, []);
    /**
     * Set the {isActive} state to false and memoize the function.
     * @type {(function(): void)|*}
     */
    const deactivate = useCallback(() => {
        setIsActive(false);
        inputRef?.current?.blur();
    }, []);
    /**
     * The InlineEditable class names.
     * @type {string}
     */
    const inlineEditableClassNameList = classNames('c-inline-editable', className).trim();
    /**
     * Edit Button
     * @type {JSX.Element}
     */
    const editButton = (React.createElement(Button, { link: true, simple: true, small: true, hiddenLabel: true, ariaLabel: editLabel, className: "c-inline-editable__btn", type: "button", icon: "semantic-edit", "data-tr-component": "Button", "data-tr-function": "Edit inline value", onClick: activate }));
    const submitButton = (React.createElement(Button, { hiddenLabel: true, className: "c-inline-editable__submit", type: "submit", icon: "semantic-checkmark", "data-tr-component": "Button", ariaLabel: submitLabel, "data-tr-function": "Submit inline value", disabled: value === submittedValue || !valid, onClick: deactivate }));
    const resetButton = (React.createElement(Button, { secondary: true, hiddenLabel: true, type: "reset", ariaLabel: resetLabel, className: "c-inline-editable__cancel", icon: "semantic-crossed-out", "data-tr-component": "Button", "data-tr-function": "Reset inline value", onClick: deactivate }));
    return (React.createElement("form", { className: inlineEditableClassNameList, onReset: onReset, onSubmit: onSubmit, "aria-hidden": isActive ? 'false' : 'true' },
        React.createElement("div", { className: "c-inline-editable__wrap" },
            React.createElement(Input, { modifierStates: "c-inline-editable__input", addonClassName: "c-inline-editable__input-addon c-input__addon--no-background", onClick: activate, size: 1, divProps: { 'data-inline-editable-size': value ?? 1 }, ...otherProps, value: value, ref: inputRef }),
            editButton,
            submitButton,
            resetButton)));
};
