import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
/**
 * Hook to manage external {@link ToastNotification} selection.
 *
 * @param {boolean} [defaultOpen=true] - Hide/show the {@link ToastNotification}.
 * @returns {object}
 */
export const useToastOpen = (defaultOpen = true) => {
    const [open, setOpen] = useState(defaultOpen);
    return {
        props: { open: !!open, onClose: useCallback(() => setOpen(false), []) },
        open,
        setOpen,
    };
};
/**
 * Bronson ToastNotifications component.
 * Groups all related {@link ToastNotification}.
 * @constructor
 */
export function ToastNotifications({ children, description, testId, ...otherProps }) {
    return (React.createElement("aside", { className: "c-toast-notifications", "aria-label": description, "data-testid": testId, ...otherProps }, children));
}
/**
 * Bronson ToastNotification component (nested).
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-toast-notification.html
 * @constructor
 */
export function ToastNotification({ children, className, closeLabel = 'Close Toast Notification', error, info, onClose, open = true, success, warning, testId, ...otherProps }) {
    const classNameList = classNames('c-toast-notification', {
        // Convenience props from Bronson variants.
        'c-toast-notification--default': !(info || success || warning || error),
        'c-toast-notification--info': info,
        'c-toast-notification--success': success,
        'c-toast-notification--warning': warning,
        'c-toast-notification--error': error,
        'is-closed': !open,
    }, className).trim();
    /**
     * Set the initial height to make CSS animations work properly.
     * @TODO: Should be refactored with the ReactTransitionGroup add-on, @see BRON-7532.
     */
    //   const toastRef = useRef(null)
    //   const [initialHeight, setInitialHeight] = useState(null)
    //   useEffect(() => {
    //     if (toastRef && toastRef.current) {
    //       const h = toastRef.current.clientHeight
    //       if (!!open && !initialHeight) {
    //         setInitialHeight(h)
    //       }
    //     }
    //   }, [open, initialHeight])
    return (
    /**
     * Need to pass `[inert=""]` as React currently does not support HTMLElement.inert mapping.
     * @see https://github.com/facebook/react/pull/24730
     * @TODO: Remove once React supports direct `[inert]` usage.
     * @see BRON-11871
     */
    React.createElement("article", { className: classNameList, "aria-live": "polite", "aria-hidden": !open, 
        // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
        inert: open ? null : '', role: "status", "data-testid": testId, ...otherProps },
        React.createElement("div", { className: "c-toast-notification__content" },
            React.createElement("div", { className: "c-toast-notification__body" },
                React.createElement("p", null, children)),
            React.createElement("button", { className: "c-toast-notification__close", title: closeLabel, type: "button", onClick: onClose },
                React.createElement("span", { className: "u-visually-hidden" }, closeLabel)))));
}
